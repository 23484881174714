<template>
    <div class="menu_index" :class="$store.state.menuShow ? 'show' : ''">

        <div class="menu_collapse" :class="$store.state.menuShow ? 'show' : ''">

            <el-menu class="elementMenu" router :collapse="!$store.state.menuShow" @open="handleOpen"
                @close="handleClose" :default-active="activeRouter">

                <!-- <template v-for="(menu, index) in routes">

                    <el-submenu :index="menu.url" v-if="menu.children">
                        <template slot="title">
                            <i class="layui-icon" :class="menu.icon"></i>
                            <span slot="title">{{menu.name}}</span>
                        </template>
                        <el-menu-item :index="menuSub.url" v-for="(menuSub, ind) in menu.children"
                            :key="`menuSub_${ind}`">{{menuSub.name}}</el-menu-item>
                    </el-submenu>

                    <el-menu-item :index="menu.url" v-else>

                        <i class="layui-icon" :class="menu.icon"></i>

                        <span slot="title">{{menu.name}}</span>

                    </el-menu-item>

                </template> -->



                <!-- <template v-for="(menu, index) in routes">

                    <el-submenu :index="menu.id + ' '"
                        v-if="menu.children&&menu.children.filter(x=>{return x.type!=4}).length>0 ">

                        <template slot="title">
                            <i class="layui-icon" :class="menu.icon"></i>
                            <span slot="title">{{menu.name}}</span>
                        </template>
                        <el-menu-item :index="menuSub.url"
                            v-for="(menuSub, ind) in menu.children.filter(x=>{return x.type!=4})"
                            :key="`menuSub_${ind}`">{{menuSub.name}}</el-menu-item>

                    </el-submenu>

                    <el-menu-item :index="menu.url" v-else>
                        <i class="layui-icon" :class="menu.icon"></i>
                        <span slot="title">{{menu.name}}</span>
                    </el-menu-item>

                </template> -->



                <template v-for="(menu, index) in routes">

                    <el-submenu :index="menu.id + ' '"
                        v-if="menu.children&&menu.children.filter(x=>{return x.type!=4}).length>0 ">

                        <template slot="title">
                            <i class="layui-icon" :class="menu.icon"></i>
                            <span slot="title">{{menu.name}}</span>
                        </template>

                        <el-menu-item :id="'menu'+index" :index="menuSub.route"
                            v-for="(menuSub, ind) in menu.children.filter(x=>{return x.type!=4})"
                            :key="`menuSub_${ind}`">{{menuSub.name}} {{menuSub.route?"":"待开发"}}</el-menu-item>

                    </el-submenu>

                    <el-menu-item :id="'menu'+index" :index="menu.route" v-else v-show="menu.name!='个人中心'">
                        <i class="layui-icon" :class="menu.icon"></i>
                        <span slot="title">{{menu.name}} {{menu.route?"":"待开发"}}</span>
                    </el-menu-item>

                </template>

            </el-menu>

        </div>


        <!-- 大标题 -->
        <div v-if="$store.state.menuShow" class="title">
            {{ $store.state.title }}
        </div>


        <div v-else class="logo">
            <!-- logo -->
            <img style="margin-top: 10px;" src="http://yifei-php-admin.tjjtln.com/static/admin/assets/images/favicon.ico" alt="">
        </div>

    </div>
</template>
<script>
    // import {
    //     routes
    // } from '@/router'

    export default {
        data() {
            return {
                // routes,
                route: this.$route.path,


                activeRouter: ""
            }
        },

        computed: {
            routes() {
                var route = JSON.parse(localStorage.getItem('user')).menu
                return route
            },
            isMenuShow() {
                console.log("AAAAAAAAAAAAAAAAAAAAAA", this.routes);
                return this.$store.state.menuShow
            }
        },

        watch: {
            // 菜单栏切换时关闭所有菜单子集
            isMenuShow(newvalue, oldvalue) {
                this.CloseMenuExcept()
            },
            routes(value) {


            }
        },

        methods: {

            clickMenu(val) {

                console.log("####################", val);

                this.activeRouter = val

            },


            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },



            // 关闭所有菜单子集
            CloseMenuExcept(val) {

                var array = this.$refs.FanfuMenuItem;

                if (array == true) {
                    for (let a = 0; a < array.length; a++) {
                        if (a != val) {
                            array[a].closeFun()
                        }
                    }
                }

            }

        },

    }
</script>
<style lang="scss" scoped>
    @import '@/assets/var.scss';

    .menu_index {
        height: 100%;
        background: #fff;
        transition: width 0.3s;
        box-shadow: $--layout-shadow;
        width: 64px;
        position: relative;
        z-index: 1000;

        overflow-x: hidden;

        &.show {
            width: $--menu-width;
        }

        .title {
            font-size: 18px;
            text-align: center;
            height: 69px;
            line-height: 69px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            font-weight: bold;
            color: #6A83FC;

            // width: calc($--menu-width - 0px);

            // border: 1px purple dotted;
            box-sizing: border-box;

            position: fixed;
            top: 0;
            left: 0px;
            background-color: #fff;

            padding-left: 10px;

            padding-right: 25px;
        }

        .logo {
            font-size: 18px;
            text-align: center;
            height: 69px;
            line-height: 69px;

            width: 64px;

            // border: 2px green dotted;
            // box-sizing: border-box;
            position: fixed;
            top: 0;
            left: 0px;
            background-color: #fff;
        }

        .menu_collapse {
            width: 64px;
            transition: width 0.3s;

            // margin-left: 5px;

            position: relative;
            left: 47%;
            transform: translateX(-50%);

            margin-top: 70px;

            &.show {
                width: $--menu-width;
            }

        }

    }



    .el-footer1 {

        // border: 1px red dotted;
        box-sizing: border-box;

        // height: 100%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #576193;

        text-align: center;
        padding: 5px;
    }

    .el-footer2 {
        display: none;
    }

    // 菜单  
    .elementMenu {
        // border: 1px red dotted;
        // box-sizing: border-box;
        // width: 200px;
        width: 90%;
        margin: 0 auto;

        // padding: 10px;
        .el-menu-item,
        .el-submenu {
            margin: 0 auto;
        }

    }

    /deep/.el-tooltip {
        // border: 1px greenyellow solid;
        // box-sizing: border-box;
        padding: 0px !important;
        text-align: center;
    }

    /deep/.el-submenu__title {
        // border: 1px greenyellow solid;
        // box-sizing: border-box;
        padding: 0px !important;
        text-align: center;
    }

    .show {

        /deep/.el-submenu__title {
            // border: 1px red solid !important;
            // box-sizing: border-box;
            padding: 0 20px !important;
            text-align: left;



        }
    }

    .el-menu-item.is-active {
        // color: #768dfc;
        background-color: #768dfc;
        color: #fff;
        border-radius: 10px;
    }



    .show .el-submenu__title,
    .el-menu-item {
        span {
            // border: 1px red solid !important;
            // box-sizing: border-box;
        }

        i {
            // border: 1px red solid !important;
            // box-sizing: border-box;
            // margin-left: -10px;
            margin-right: 5px;
            // display: inline;
            // width: 50px !important;
            // height: 20px !important;
        }
    }
</style>