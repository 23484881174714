<template>
    <div class="layout_index">
        <Menu ref="menu" />
        <div class="layout_main" ref="layoutMain" :class="$store.state.menuShow ? 'show' : ''">
            <Top />
            <div class="layout_main_content" :class="{'noPadding':isHome}">
                <Fanfu-Scrollbar>
                    <div class="main_content">

                        <router-view :key="activeDate" />

                    </div>
                </Fanfu-Scrollbar>
            </div>

            <p class=" el-footer1">技术支持：Copyright © 一联（天津）物联科技产业发展有限责任公司 All rights reserved. <span
                    style="color: transparent;">20231222170647</span></p>

        </div>

    </div>
</template>

<script>
    import Top from './components/Top'
    import Menu from './components/Menu'
    export default {
        components: {
            Top,
            Menu
        },

        data() {
            return {

                isHome: false,

                activeDate: null

            }
        },

        created() {
            this.updatedupdated()
        },

        updated() {
            this.updatedupdated()
        },

        mounted() {
            this.$refs.layoutMain.addEventListener("click", this.bodyCloseMenus); //添加点击事件

            this.activeDate = new Date()
            console.log(this.activeDate);
        },

        methods: {

            bodyCloseMenus() { //点击后通知菜单关闭二级菜单
                this.$refs.menu.CloseMenuExcept();
            },

            updatedupdated() {
                console.log(this.$route.path);

                // this.$refs.menu.clickMenu(this.$route.path)

                setTimeout(x => {
                    console.log("===============================>>>>>>>", this.$route.path);
                    this.$refs.menu.clickMenu(this.$route.path)
                }, 500)




                if (this.$route.path == '/pages/Home') {
                    this.isHome = true
                } else {
                    this.isHome = false
                }
            }

        },

    }
</script>

<style lang="scss" scoped>
    @import '@/assets/var.scss';

    .layout_index {
        height: 100vh;
        display: flex;
        width: 100%;

        // border: 1px red solid;
        // box-sizing: border-box;
        // min-width: 1225px;
        // overflow: hidden;

        .layout_main {
            height: 100%;
            transition: width 0.3s;
            width: calc(100vw - 64px);

            // border: 1px red solid;
            // box-sizing: border-box;
            // min-width: calc(1225px - 64px);

            .el-footer1 {
                // display: flex;
                // justify-content: center;
                // align-content: center;
                // align-items: center;

                // border: 1px red dotted;
                // box-sizing: border-box;


                height: 20px;
                line-height: 20px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #576193;

                margin-top: 10px;
                // line-height: 56px;
                // height: 56px;
                white-space: wrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;

                text-align: center;
            }

            .layout_main_content {
                padding: $--main-content-margin;
                padding-right: 4px;
                padding-bottom: 0px;

                background: #fff;
                margin: $--main--margin;
                margin-bottom: 0px;

                // height: calc(100vh - #{$--top-height} - #{$--main-content-margin} * 2 - #{$--main--margin} * 2);

                height: calc(100vh - #{$--top-height} - #{$--main-content-margin} * 2 - #{$--main--margin} * 2 + 20px);

                box-shadow: $--layout-shadow;

                // padding-top: 0;

                // border: 1px red dotted;
                box-sizing: border-box;

                .main_content {
                    margin-right: calc(#{$--main-content-margin} - 4px);
                    // border: 1px red dotted;
                    box-sizing: border-box;

                    // height: 100%;

                    // border: 1px green solid;
                    // box-sizing: content-box;

                }
            }

            &.show {
                width: calc(100vw - #{$--menu-width});
            }
        }
    }

    .noPadding {

        padding: 0 !important;

    }
</style>